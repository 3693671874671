
import { Options, Vue } from "vue-class-component";
import axios from "@/plugins/axios";
import { ElLoading } from 'element-plus'
@Options({
    props: {
        idConnect: '',
        typeMapping: '',
        value: false,
        connectTo: false
    },
})
export default class ShipmentMappingPage extends Vue {
    headers = {
        "token-user": localStorage.getItem("tokenUser"),
        "account": localStorage.getItem("account")
    }
    modalTitle= ''
    listAttributesSAAS= []
    listAttributes = []
    listAttributesFrom = []
    listConnectMapping= []
    idConnect = ''
    typeMapping = ''
    dataForm:any = {
      id: '',
      type:'',
      mapping: [],
      mappingShipping: [],
      mappingPayment: []
    }
    dataFormMapping = {
        valueAttributeCustom: '',
        valueAttributeFromCustom: '',
        connect: '',
        saas: '',
        isCustom: false
    }
    isAttributeFromCustom = false
    isAttributeCustom = false
    value: any
    
    listShippingCarriers = []
    listShippingCarriersFrom = []

    listPaymentCarriers = []
    listPaymentCarriersFrom = []

    get dataFormMappingAttribute() {
        const mappingAttribute = JSON.parse(JSON.stringify(this.dataForm?.mapping || [])) || [];
        mappingAttribute.unshift(...[
            {
                connect: 'Sku',
                saas: 'sku',
                isCustom: false,
                isHiddenButtonRemove: true,
            },
            {
                connect: 'Title',
                saas: 'title',
                isCustom: false,
                isHiddenButtonRemove: true,
            },
            {
                connect: 'Description',
                saas: 'description',
                isCustom: false,
                isHiddenButtonRemove: true,
            },
            {
                connect: 'Cost',
                saas: 'cost',
                isCustom: false,
                isHiddenButtonRemove: true,
            },
            {
                connect: 'Weight',
                saas: 'weight',
                isCustom: false,
                isHiddenButtonRemove: true,
            },
            {
                connect: 'Images',
                saas: 'images',
                isCustom: false,
                isHiddenButtonRemove: true,
            },
        ]);
        return mappingAttribute;
    }

    async created (){
        if(this.typeMapping == 'Attribute'){
            this.getConnectMapping()
        }
        if(this.typeMapping == 'Shipping'){
            this.getConnectShipping()
        }
        if(this.typeMapping == 'Payment'){
            this.getConnectPayment()
        }

        this.modalTitle = this.typeMapping + ' Mapping';
       if(this.typeMapping == 'Shipping'){
             this.modalTitle = 'Shipment Mapping' 
        }
        this.dataForm.id = this.idConnect
        
    }

    async getConnectMapping() {  
        await this.$store.dispatch("setLoading", true, { root: true });
        let res:any = await axios.get('user/connect/attribute-mapping', { 
            params: { id: this.idConnect },
            headers : this.headers,
        })
        if (res) {
            if(res.name === "TypeError") {
                return this.$message.warning(res.message)
            } else {
                this.listAttributesFrom = res.attributeFrom
                this.listAttributes = res.attribute
                this.dataForm.mapping = res.mapping
            }
        }
        await this.$store.dispatch("setLoading", false, { root: true });
    }
        
    async getConnectShipping() {
        const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        })
        let res:any = await axios.get('user/connect/attribute-shipping', { 
            params: { id: this.idConnect },
            headers : this.headers,
        })
        if (res) {
            if(res.name === "TypeError") {
                return this.$message.warning(res.message)
            } else {
                this.listShippingCarriersFrom = res.shippingMethodsFrom
                this.listShippingCarriers = res.shippingMethods
                this.dataForm.mappingShipping = res.mappingShipping
            }
        }
        loading.close()
    }

    async getConnectPayment() {
        await this.$store.dispatch("setLoading", true, { root: true });
        let res:any = await axios.get('user/connect/attribute-payment', { 
            params: { id: this.idConnect },
            headers : this.headers,
        })
        if (res) {
            if(res.name === "TypeError") {
                return this.$message.warning(res.message)
            } else {
                this.listPaymentCarriersFrom = res.paymentMethodsFrom
                this.listPaymentCarriers = res.paymentMethods
                this.dataForm.mappingPayment = res.mappingPayment
            }
        }
        await this.$store.dispatch("setLoading", false, { root: true });
    }

    handleSelectAttribute () {
        this.dataFormMapping.valueAttributeCustom = ''
        this.isAttributeCustom = false
        this.dataFormMapping.isCustom = false
        if (this.dataFormMapping.connect === 'attributeCustom') {
            this.isAttributeCustom = true
            this.dataFormMapping.isCustom = true
        }
    }
    handleSelectAttributeFrom () {
        this.dataFormMapping.valueAttributeFromCustom = ''
        this.isAttributeFromCustom = false
        if (this.dataFormMapping.saas === 'attributeFromCustom') {
            this.isAttributeFromCustom = true
        }
    }
    // Mapping Attribute
    // Mapping Attribute
    handleAddAttribute(){
        if (this.dataFormMapping.valueAttributeCustom.length) {
            this.dataFormMapping.connect = this.dataFormMapping.valueAttributeCustom
        }
        if (this.dataFormMapping.valueAttributeFromCustom.length) {
            this.dataFormMapping.saas = this.dataFormMapping.valueAttributeFromCustom
        }
        if (this.dataFormMapping.connect ===  '' || this.dataFormMapping.saas === '') {
            return this.$message.warning('Please do not leave it blank', 'Error message!!!')
        }
        let item: any
        for (item of this.dataFormMappingAttribute) {
            if (item.connect === this.dataFormMapping.connect && item.saas === this.dataFormMapping.saas) {
                return this.$message.warning('This mapping value is exist', 'Error message!!!')
            }
        }
        this.dataForm.mapping.push({
            connect: this.dataFormMapping['connect'],
            saas: this.dataFormMapping['saas'],
            isCustom: this.dataFormMapping.isCustom,
        })
        this.dataFormMapping.valueAttributeFromCustom = ''
        this.dataFormMapping.valueAttributeCustom = ''
        this.dataFormMapping.connect = ''
        this.dataFormMapping.saas= ''
    }
    // Mapping Shipping
    handleAddShipping(){
        if (this.dataFormMapping.connect ===  '' || this.dataFormMapping.saas === '') {
                return this.$message.warning('Please do not leave it blank', 'Error message!!!')
            }
        let item: any
        for (item of this.dataForm.mappingShipping) {
            if (item.connect === this.dataFormMapping.connect && item.saas === this.dataFormMapping.saas) {
                return this.$message.warning('This mapping value is exist', 'Error message!!!')
            }
        }
        this.dataForm.mappingShipping.push({
            connect: this.dataFormMapping['connect'],
            saas: this.dataFormMapping['saas'],
            isCustom: this.dataFormMapping.isCustom,
        })
        this.dataFormMapping.connect = ''
        this.dataFormMapping.saas= ''
    }
    // Mapping Payment
    handleAddPayment(){
        if (this.dataFormMapping.connect ===  '' || this.dataFormMapping.saas === '') {
                return this.$message.warning('Please do not leave it blank', 'Error message!!!')
            }
        let item: any
        for (item of this.dataForm.mappingPayment) {
            if (item.connect === this.dataFormMapping.connect && item.saas === this.dataFormMapping.saas) {
                return this.$message.warning('This mapping value is exist', 'Error message!!!')
            }
        }
        this.dataForm.mappingPayment.push({
            connect: this.dataFormMapping['connect'],
            saas: this.dataFormMapping['saas'],
            isCustom: this.dataFormMapping.isCustom,
        })
        this.dataFormMapping.connect = ''
        this.dataFormMapping.saas= ''
    }

    async handleSaveMapping(){
        await this.$store.dispatch("setLoading", true, { root: true });

        var typeUrl = 'mapping';
        if(this.typeMapping === 'TAG')
            typeUrl = 'tag';
        if(this.typeMapping === 'Shipping')
            typeUrl = 'mapping-shipping';
        if(this.typeMapping === 'Payment')
            typeUrl = 'mapping-payment';

        await axios.post('user/connect/'+ typeUrl, this.dataForm, { 
            headers : this.headers,
        }).then( (response: any) => {
            return this.$message.success(response.message)
        },  (response: any) => {
            return this.$message.warning(response.message)
        });
        await this.$store.dispatch("setLoading", false, { root: true });
    }
    handleRemoveOption (index: any) {
        if(this.typeMapping === 'Attribute') {
            this.dataForm.mapping.splice(index, 1)
        }
        if(this.typeMapping === 'Shipping') {
            this.dataForm.mappingShipping.splice(index, 1)
        }
        if(this.typeMapping === 'Payment') {
            this.dataForm.mappingPayment.splice(index, 1)
        }
    }
    get textValue() {
        return this.value
    }

    set textValue(value) {
        this.$emit('input', value)
    }
    handleHideModal (){
        this.$emit('closeModalMapping')
    }
}
